export var getQueryVariable = function (queryName) {
    var query = window.location.search.substring(1);
    return getQueryVariableFromQuery(query, queryName);
};
export var getQueryVariableFromUrl = function (url, queryName) {
    var query = url.replace(/^.*\?/, "");
    return getQueryVariableFromQuery(query, queryName);
};
var getQueryVariableFromQuery = function (query, queryName) {
    return new URLSearchParams(query).get(queryName) || undefined;
};
