import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./useAppSelector";
import { addNotification, removeNotificaionCategory } from "Redux/slices/appSlice";
export var useIncreaseNotification = function () {
    var dispatch = useDispatch();
    var _a = useAppSelector(function (state) { return state.app; }), notifications = _a.notifications, discountIncrease = _a.discountIncrease;
    var _b = useAppSelector(function (state) { return state.userInfo; }), user = _b.user, isUserTypeDefined = _b.isUserTypeDefined;
    useEffect(function () {
        if (discountIncrease === undefined || !isUserTypeDefined)
            return;
        if (discountIncrease === null || (user === null || user === void 0 ? void 0 : user.discountIncrease) === null) {
            dispatch(removeNotificaionCategory("discountIncrease"));
            localStorage.removeItem("lastTimeStampOfDiscountIncrease");
        }
        else {
            var isDiscountIncreaseNotificationMissing = !notifications.find(function (notification) { return notification.category === "discountIncrease"; });
            var lastTimeStampOfDiscountIncrease = localStorage.getItem("lastTimeStampOfDiscountIncrease");
            var currentTimeStampOfDiscountIncrease = new Date().toISOString().split("T")[0];
            if (discountIncrease &&
                ((isDiscountIncreaseNotificationMissing && !lastTimeStampOfDiscountIncrease) ||
                    (lastTimeStampOfDiscountIncrease &&
                        new Date(currentTimeStampOfDiscountIncrease) > new Date(lastTimeStampOfDiscountIncrease)))) {
                setTimeout(function () {
                    var isCatalogUrl = ["/categories", "/brands", "/catalog", "/specials"].some(function (url) {
                        return location.pathname.startsWith(url);
                    });
                    dispatch(removeNotificaionCategory("discountIncrease"));
                    !isCatalogUrl &&
                        dispatch(addNotification({
                            category: "discountIncrease",
                            id: Date.now().toString(),
                            content: discountIncrease,
                        }));
                    localStorage.removeItem("lastTimeStampOfDiscountIncrease");
                }, 10000);
            }
        }
    }, [user, discountIncrease]);
};
