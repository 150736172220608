var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./CustomCheckbox.scss";
import React, { useEffect, useState } from "react";
export var CustomCheckbox = function (_a) {
    var checked = _a.checked, className = _a.className, darkMode = _a.darkMode, disabled = _a.disabled, id = _a.id, labelText = _a.labelText, onChangeHandler = _a.onChangeHandler, _b = _a.size, size = _b === void 0 ? "normal" : _b, statusCalb = _a.statusCalb;
    var _c = __read(useState(!!checked), 2), inputChecked = _c[0], setInputChecked = _c[1];
    useEffect(function () {
        setInputChecked(!!checked);
    }, [checked]);
    return (React.createElement("div", { className: "customCheckbox" + (className ? " ".concat(className) : ""), onClick: function (e) {
            e.stopPropagation();
            e.preventDefault();
            if (disabled)
                return;
            setInputChecked(!inputChecked);
            onChangeHandler && onChangeHandler(id, !inputChecked);
            statusCalb && statusCalb(!inputChecked);
        } },
        React.createElement("input", { type: 'checkbox', id: id, checked: inputChecked, disabled: disabled, className: "customCheckbox__input" + " customCheckbox__input_".concat(size), onChange: function () { } }),
        labelText && (React.createElement("label", { htmlFor: id, className: (size === "normal" ? "typography_captionTxt" : "typography_tec_13_medium") + (darkMode ? " blackLabel" : "") }, labelText))));
};
